import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiTextArea } from '../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "uitextarea"
    }}>{`UiTextArea`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/form/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Component used to render text area fields`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/form`}</p>
    </blockquote>
    <h2 {...{
      "id": "uitextarea-with-placeholder"
    }}>{`UiTextArea with placeholder`}</h2>
    <Playground __position={1} __code={'<UiTextArea\n  placeholder=\"First Name\"\n  onChange={value => {\n    console.log(value)\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiTextArea,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiTextArea placeholder="First Name" onChange={value => {
        console.log(value);
      }} mdxType="UiTextArea" />
    </Playground>
    <h2 {...{
      "id": "uitextarea-with-label"
    }}>{`UiTextArea with label`}</h2>
    <Playground __position={2} __code={'<UiTextArea label=\"Fist Name\" labelOnTop cols={50} rows={3} />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiTextArea,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiTextArea label="Fist Name" labelOnTop cols={50} rows={3} mdxType="UiTextArea" />
    </Playground>
    <h2 {...{
      "id": "uitextarea-disabled"
    }}>{`UiTextArea disabled`}</h2>
    <Playground __position={3} __code={'<UiTextArea label=\"Fist Name\" disabled />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiTextArea,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiTextArea label="Fist Name" disabled mdxType="UiTextArea" />
    </Playground>
    <h2 {...{
      "id": "uitextarea-with-state"
    }}>{`UiTextArea with state`}</h2>
    <Playground __position={4} __code={'<UiTextArea label=\"Fist Name\" category=\"positive\" />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiTextArea,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiTextArea label="Fist Name" category="positive" mdxType="UiTextArea" />
    </Playground>
    <h2 {...{
      "id": "uitextarea-with-error-state-and-error-message"
    }}>{`UiTextArea with error state and error message`}</h2>
    <Playground __position={5} __code={'<UiTextArea\n  label=\"Fist Name\"\n  error=\"Some error\"\n  category=\"error\"\n  labelOnTop\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiTextArea,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiTextArea label="Fist Name" error="Some error" category="error" labelOnTop mdxType="UiTextArea" />
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiTextArea} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      